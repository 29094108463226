import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The best way to get in touch is either via Twitter or email.
I'm `}<a parentName="p" {...{
        "href": "http://twitter.com/ediblecode"
      }}>{`@ediblecode on Twitter`}</a>{` and you can email me at my first name at this domain.`}</p>
    <p>{`I'm `}<a parentName="p" {...{
        "href": "https://github.com/ediblecode"
      }}>{`ediblecode on GitHub`}</a>{` and you can find me on `}<a parentName="p" {...{
        "href": "http://stackoverflow.com/users/486434/ian-routledge"
      }}>{`Stack Overflow`}</a>{`.`}</p>
    <p>{`Finally, `}<a parentName="p" {...{
        "href": "http://uk.linkedin.com/in/ianroutledge"
      }}>{`connect with me on LinkedIn`}</a>{` if that's your thing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      